import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../actions/userActions";
import { getAllSchoolcodes } from "../actions/schoolcodeActions";
import { getAllClassss } from "../actions/classsActions";
import { getAllStudents } from "../actions/studentActions"; // Import getAllStudents action
import Error from "../components/Error";
import Success from "../components/Success";
import Loading from "../components/Loading";
import axios from "axios"; // Import axios to make API requests

export default function Registerscreen() {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [middlename, setmiddlename] = useState("");
  const [name, setname] = useState("");
  const [school, setschool] = useState("");
  const [schoolcode, setschoolcode] = useState("");
  const [classs, setclasss] = useState("");
  const [section, setsection] = useState("");
  const [city, setcity] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [paid, setpaid] = useState(false);
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [registrationType, setRegistrationType] = useState("schoolCode");
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
  const [rollNumber, setRollNumber] = useState("");

  const registerstate = useSelector((state) => state.registerUserReducer);
  const { error, loading, success } = registerstate;
  const schoolcodes = useSelector(
    (state) => state.getAllSchoolcodesReducer.schoolcodes
  );

  const classssState = useSelector((state) => state.getAllClassssReducer);
  const {
    classss: classes,
    loading: classssLoading,
    error: classssError,
  } = classssState;

  const studentsstate = useSelector((state) => state.getAllStudentsReducer);
  const { students } = studentsstate;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSchoolcodes());
    dispatch(getAllClassss()); // Fetch all classes
    dispatch(getAllStudents()); // Fetch all students

    // Make the API call to delete unverified users
    // const deleteUnverifiedUsers = async () => {
    //   try {
    //     const response = await axios.delete("/api/users/deleteUnverified");
    //     console.log(response.data.message); // Logs the response from the server
    //   } catch (error) {
    //     console.error("Error deleting unverified users:", error);
    //   }
    // };

    // deleteUnverifiedUsers(); // Call the function to delete unverified users on page load
  }, [dispatch]);

  useEffect(() => {
    const allFieldsFilled =
      firstname !== "" &&
      lastname !== "" &&
      classs !== "" &&
      city !== "" &&
      mobile !== "" &&
      email !== "" &&
      password !== "" &&
      cpassword !== "" &&
      (registrationType === "schoolCode" ? section !== "" : true) &&
      (registrationType === "schoolCode" ? schoolcode !== "" : true) &&
      (registrationType === "schoolCode" ? rollNumber !== "" : true);
    setIsAllFieldsFilled(allFieldsFilled);
  }, [
    firstname,
    lastname,
    schoolcode,
    classs,
    section,
    city,
    mobile,
    email,
    password,
    cpassword,
    registrationType,
    rollNumber,
  ]);

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setmobile(value);
    }
  };

  const handleTextChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setter(value);
    }
  };

  async function register() {
    if (password !== cpassword) {
      alert("Passwords do not match");
    } else {
      try {
        // Check if email already exists
        const response = await axios.post("/api/users/checkemail", { email });
        if (response.data.exists) {
          alert(
            "Email already registered. Register with another email account."
          );
          return;
        }

        if (registrationType === "schoolCode") {
          const isValid = schoolcodes.some(
            (code) => code.code === schoolcode.trim()
          );
          setIsSchoolCodeValid(isValid);
          if (!isValid) {
            alert("School Code is Invalid");
            return;
          }
        }

        const matchedStudent = students.find(
          (student) =>
            student.firstname &&
            student.classs &&
            student.rollnumber &&
            student.firstname.trim().toLowerCase() ===
              firstname.trim().toLowerCase() &&
            student.classs == classs &&
            student.rollnumber.trim() == rollNumber.trim()
        );

        if (registrationType === "schoolCode" && !matchedStudent) {
          alert(
            "You are not yet enrolled with us. Please contact school admin or register without the school code."
          );
          return;
        }

        if (mobile.length < 10) {
          alert("Entered mobile number is less than 10-digit.");
          return;
        }

        const user = {
          firstname: firstname.trim(),
          middlename: middlename.trim(),
          lastname: lastname.trim(),
          name: `${firstname.trim()} ${middlename.trim()} ${lastname.trim()}`,
          key: `${firstname.trim()}${classs.trim()}${rollNumber.trim()}`,
          mobile: mobile.trim(),
          school: school.trim(),
          schoolcode:
            registrationType === "schoolCode" ? schoolcode.trim() : "Empty",
          paid: registrationType === "schoolCode" ? true : false,
          classs: classs.trim(),
          rollNumber:
            registrationType === "schoolCode" ? rollNumber.trim() : "",
          section: registrationType === "schoolCode" ? section.trim() : "",
          city: city.trim(),
          email: email.trim().toLowerCase(),
          password: password.trim(),
        };

        console.log(user);

        // Save email to local storage
        localStorage.setItem("registeredEmail", user.email);

        // Send the new user to the backend to register
        dispatch(registerUser(user));
      } catch (error) {
        console.error("Error during registration", error);
        alert("An error occurred during registration. Please try again.");
      }
    }
  }

  return (
    <div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded-5">
          {loading && <Loading />}
          {/* {success && <Success success="User Registered Successfully" />} */}
          {/* {error && <Error error="Email already registered" />} */}

          <h2
            className="text-center m-2"
            style={{
              fontSize: "35px",
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            Register
          </h2>
          <div>
            <div>
              <input
                required
                type="text"
                placeholder="First Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control"
                value={firstname}
                onChange={handleTextChange(setfirstname)}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="Middle Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control"
                value={middlename}
                onChange={handleTextChange(setmiddlename)}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="Last Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control"
                value={lastname}
                onChange={handleTextChange(setlastname)}
                style={{
                  border: "1px solid black",
                  marginBottom: "20px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="registrationType"
                id="schoolCode"
                value="schoolCode"
                checked={registrationType === "schoolCode"}
                onChange={() => setRegistrationType("schoolCode")}
                style={{
                  border: "1px solid #ced4da",
                  boxShadow: "none",
                  marginRight: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
              <label
                className="form-check-label"
                htmlFor="schoolCode"
                style={{
                  marginLeft: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                Register with school code
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="registrationType"
                id="individual"
                value="individual"
                checked={registrationType === "individual"}
                onChange={() => setRegistrationType("individual")}
                style={{
                  border: "1px solid #ced4da",
                  boxShadow: "none",
                  marginRight: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
              <label
                className="form-check-label"
                htmlFor="individual"
                style={{
                  marginLeft: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                Register as an individual without school code
              </label>
            </div>
            {registrationType === "schoolCode" && (
              <div>
                <input
                  required
                  type="text"
                  placeholder="School Code"
                  className="form-control"
                  value={schoolcode}
                  onChange={(e) => {
                    setschoolcode(e.target.value);
                  }}
                  style={{
                    border: "1px solid black",
                    marginBottom: "0px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            <div className="form-group">
              <label></label>
              <select
                className="form-control"
                value={classs}
                onChange={(e) => setclasss(e.target.value)}
                style={{
                  marginBottom: "0px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                <option value="">Select Class</option>
                {classes &&
                  classes.map((cls) => (
                    <option key={cls._id} value={cls.cls}>
                      {cls.cls}
                    </option>
                  ))}
              </select>
            </div>

            {registrationType === "schoolCode" && (
              <div className="form-group">
                <label></label>
                <select
                  className="form-control"
                  value={section}
                  onChange={(e) => setsection(e.target.value)}
                  style={{
                    marginBottom: "0px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                >
                  <option value="">Select Class Section</option>
                  <option key="Section Unavailable" value="Section Unavailable">
                    Section Unavailable
                  </option>
                  <option key="A" value="A">
                    A
                  </option>
                  <option key="B" value="B">
                    B
                  </option>
                  <option key="C" value="C">
                    C
                  </option>
                  <option key="D" value="D">
                    D
                  </option>
                  <option key="E" value="E">
                    E
                  </option>
                  <option key="F" value="F">
                    F
                  </option>
                  <option key="G" value="G">
                    G
                  </option>
                  <option key="H" value="H">
                    H
                  </option>
                </select>
              </div>
            )}

            {registrationType === "schoolCode" && (
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Roll Number"
                  value={rollNumber}
                  onChange={(e) => setRollNumber(e.target.value)}
                  style={{
                    border: "1px solid black",
                    marginBottom: "10px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            {registrationType === "schoolCode" && (
              <div>
                <input
                  required
                  type="text"
                  placeholder="School Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                  className="form-control"
                  value={school}
                  onChange={handleTextChange(setschool)}
                  style={{
                    border: "1px solid black",
                    marginBottom: "10px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            <div>
              <input
                required
                type="text"
                placeholder="City     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control"
                value={city}
                onChange={handleTextChange(setcity)}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="10-digit Mobile Number"
                className="form-control"
                value={mobile}
                onChange={handleMobileChange}
                maxLength="10"
                pattern="\d{10}"
                title="Please enter a valid 10-digit mobile number"
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="Email"
                className="form-control"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <input
              required
              type="password"
              placeholder="Password"
              className="form-control"
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              style={{
                border: "1px solid black",
                marginBottom: "10px",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />
            <input
              required
              type="password"
              placeholder="Confirm Password"
              className="form-control mb-3"
              value={cpassword}
              onChange={(e) => {
                setcpassword(e.target.value);
              }}
              style={{
                border: "1px solid black",
                marginBottom: "10px",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />

            <button
              onClick={register}
              className="btn mt-3 mb-3"
              disabled={!isAllFieldsFilled}
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            >
              REGISTER
            </button>
            <br></br>
            <a
              style={{
                color: "black",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              href="/login"
            >
              Click Here To Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
